import React, { useRef } from 'react';
import MUIAutocomplete from '@mui/material/Autocomplete';
import { Select, MenuItem } from '@mui/material';
import { Radio, Checkbox } from 'antd';
import { NMCKEditor } from "../CKEditor";
import "./style.scss"

const InputContainer = (props) => {
  return (
    <div className={`container ${props.className || ''} ${props.readOnly ? 'readOnly' : ''}  ${props.disabled ? 'disabled' : ''}`} ref={props.InputPropsRef}>
      {props.children}
    </div>
  )
}

export const AutoComplete = (props) => {
  const { label, disablePortal, options, placeholder, id, name, value, disabled, readOnly, ...rest } = props;
  const direction = rest.direction || 'row'
  const isCol = rest.direction === 'column'
  const _ref = useRef(null)
  const removeFocus = () => {
    if (_ref && _ref.current) {
      const input = _ref.current.querySelector('input')
      if (input) input.blur();
    }
  }
  return (
    <div className={`${direction || 'row'} font-input ${rest.required ? 'req' : ''} ${readOnly ? 'readOnly' : ''}`} >
      <div className='row label-cont'>
        <label className='label f9 c00085 reg' htmlFor={id || name}>{label}</label>
        {rest.showColon && <span className='f9 c00085 colon' >:</span>}
      </div>
      <MUIAutocomplete
        openOnFocus
        ref={_ref}
        disablePortal={disablePortal !== undefined ? disablePortal : undefined}
        id={id || name}
        value={value}
        options={options || []}
        onChange={(e, value) => {
          e.preventDefault();
          rest.onChange && rest.onChange({ target: { name: name, value: value } })
          removeFocus()
        }}
        classes={{ root: 'f-rest', listbox: 'dropdown-list', option: "f9 option caps", groupLabel: 'f9 c00085 caps', noOptions: 'f9' }}
        disabled={disabled || readOnly}
        noOptionsText='No Matching Result'
        renderInput={(params) => {
          const { className, ..._rest } = params.inputProps
          return (
            <InputContainer InputPropsRef={params.InputProps.ref} className='row' readOnly={readOnly} disabled={disabled}>
              <input id={`${id || ''}_input`} type='text' placeholder={placeholder || label} className={`f9 input caps ${className}`} {..._rest} name={rest.name} />
              {!readOnly && <i className='icon-profile-caret c00025 f12' />}
            </InputContainer>
          )
        }}
      />
    </div>
  )
}

export const TextInput = (props) => {
  const { label, type, required, id, name, value, disabled, className, font, showColon, readOnly, radioButtons, radioHints, error, text, ...rest } = props
  const direction = rest.direction || 'row';
  const isTextArea = type === 'textarea';
  const radio = type === 'radio';
  const html = type === 'html';
  const checkbox = type === 'checkbox';
  const _ref = useRef(null)
  return (
    <div
      className={`col font-input text-input ${direction} ${required ? 'req' : ''} ${className || ''} ${isTextArea || html ? 'v-start' : ''} ${radio || checkbox ? 'v-start radio-cont' : ''} ${readOnly ? 'readOnly' : ''}`}>
      <div className='row label-cont'>
        {Boolean(label) && <label className='f9 c00085 reg label' htmlFor={`${id || name}_input`}>{label}</label>}
        {showColon && <span className='f9 c00085 reg colon' >:</span>}
      </div>
      <div className='col f-rest'>
        <InputContainer className='col w-100' readOnly={readOnly} disabled={disabled}>
          {
            isTextArea ?
              <React.Fragment>
                {
                  readOnly ?
                    <p className={`${font || 'f9'} input`}>{value}</p>
                    :
                    <textarea id={`${id || name}_input`} name={name} className={`${font || 'f9'} input`} {...rest} value={value} disabled={disabled || readOnly}></textarea>
                }
                {Boolean(rest.maxLength) && <h6 className='f9 c00025 reg count line-22'>{(value || '').length}/{rest.maxLength}</h6>}
              </React.Fragment>
              : radio ?
                <React.Fragment>
                  <Radio.Group value={value} onChange={rest.onChange} className={className} name={name} >
                    {
                      (radioButtons || []).map((radio) => {
                        return <Radio value={radio.value} key={radio.value}>{radio.label}</Radio>
                      })
                    }
                  </Radio.Group>
                  {
                    Boolean(radioHints && radioHints[value]) && <h6 className='hint f9 c777777'>{radioHints[value]}</h6>
                  }
                </React.Fragment>
                :
                checkbox ?
                  <React.Fragment>
                    <div className={props.checkboxClassName}><Checkbox onChange={rest.onChange} checked={value}><div className={props.checkboxTextClass}>{text}</div></Checkbox>;</div>
                  </React.Fragment>
                  :
                  html ?
                    <React.Fragment>
                      {
                        (readOnly || disabled) ?
                          <p className='ck-container max oy-auto' dangerouslySetInnerHTML={{ __html: value }}></p>
                          :
                          <NMCKEditor
                            initialData={value}
                            onInstanceReady={(e) => _ref.current = e.editor}
                            onChange={(e) => {
                              if (_ref.current) {
                                rest.onChange && rest.onChange({ target: { name, value: _ref.current.getData() } })
                              }
                            }}
                          />
                      }
                    </React.Fragment>
                    :
                    <input disabled={readOnly || disabled} id={`${id || name}_input`} type={type || 'text'} name={name} className={`${font || 'f9'} input`} {...rest} value={value} />
          }
        </InputContainer>
        {Boolean(error) && <h6 className='f11 cFE3333 error'>{error}</h6>}
      </div>
    </div>
  )
}
export const Dropdown = (props) => {
  const { label, id, font, name, disabled, className, primary, options, caretClassName, readOnly, ...rest } = props
  return (
    <Select
      label={label} id={id || name} name={name} disabled={disabled} classes={{ select: 'select' }}
      IconComponent={() => <i className={`icon-profile-caret f11 caret ${caretClassName || 'c00085'}`} />}
      className={`dropdown ${className || ''}  ${primary && 'primary'} ${readOnly ? 'readOnly' : ''}`} variant='outlined' {...rest}>
      {
        (options || []).map((option) => {
          return (
            <MenuItem key={option.value} value={option.value} className={`dropdown-item c0085  ${primary && 'primary'}`}>
              <div className='row'>
                {Boolean(option.icon) && <i className={`icon ${font || 'f9'} ${option.icon}`} />}
                <h6 className={`label ${font || 'reg f9'}`}>{option.label}</h6>
              </div>
            </MenuItem>
          )
        })
      }
    </Select>
  )
}
export const Form = (props) => {
  const { Fields, formData, onChange, getOptions, fieldClass, error } = props;
  return (
    Fields.map(({ attribute, ...rest }) => {
      const p = {
        value: formData[attribute] || '', key: attribute, error: error && error[attribute],
        className: `${fieldClass || 'w-100'}`, name: attribute, onChange: onChange,
        ...rest
      }
      return (
        rest.type === 'select' ?
          <AutoComplete
            {...p}
            options={getOptions(attribute)} />
          :
          <TextInput {...p} />
      )
    })
  )
}