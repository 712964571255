import { performVariableMapping } from "../VariableMapping";
export const Sections = [
  { title: "Document History", attribute: 'doc_history', defaultPage: 0, },
  { title: "Document Control", attribute: 'doc_control', defaultPage: 0, },
  { title: "Copyright Information", attribute: 'copyright', defaultPage: 0 },
  { title: "CONTENTS", attribute: 'tbl_of_content', defaultPage: 1, allowAddVariable: false },
  { title: "Purpose", attribute: 'purpose', defaultPage: 2, slNo: 1 },
  { title: "Scope", attribute: 'scope', defaultPage: 2, slNo: 2 },
  { title: "Organisation Identifier: ", attribute: 'organisation_identifier', defaultPage: 3, slNo: 3 },
  { title: "", attribute: 'common_doc_type', defaultPage: 3, slNo: 4 },
  { title: "Enforcement", attribute: 'enforcement', defaultPage: 4, slNo: 5 },
  { title: "Exception", attribute: 'exception', defaultPage: 4, slNo: 6 },
  { title: "Violation", attribute: 'violation', defaultPage: 4, slNo: 7 },
  { title: "Review", attribute: 'review', defaultPage: 5, slNo: 8 },
  { title: "Appendix", attribute: 'appendix', defaultPage: 5, slNo: 9 },
];
const SectionTitle = {
  "doc_history": { title: "Document History" },
  "doc_control": { title: "Document Control" },
  "copyright": { title: "Copyright Information" },
  "tbl_of_content": { title: "CONTENTS" },
  "purpose": { title: "Purpose", slNo: "1" },
  "scope": { title: "Scope", slNo: "2" },
  "organisation_identifier": { title: `Organisation Identifier: `, slNo: "3" },
  "common_doc_type": { title: "", slNo: "4" },
  "enforcement": { title: "Enforcement", slNo: "5" },
  "exception": { title: "Exception", slNo: "6" },
  "violation": { title: "Violation", slNo: "7" },
  "review": { title: "Review", slNo: "8" },
  "appendix": { title: "Appendix", slNo: "9" },
  "reference": { title: "Reference", slNo: "9.1" },
  "glossary": { title: "Glossary & Definition", slNo: "9.2" },
  "abbreviation": { title: "Abbreviation, Acronym, Initialism", slNo: "9.3" },
  "change_history": { title: "Change History" }
}
const ChangeHistory = { title: "Change History", attribute: 'change_history' };

const fillTableOfContents = (pages) => {
  var pageHTMLString = ``, index;
  for (let i = 0; i < pages.length; i++) {
    for (let j = 0; j < pages[i].length; j++) {
      const section = pages[i][j];
      const { attribute, showSectionTitle, isSubSection } = section;
      if (attribute !== 'tbl_of_content') {
        if (showSectionTitle) {
          let isFirstSec = attribute === 'doc_history' || attribute === 'doc_control' || attribute === 'copyright';
          pageHTMLString += `<div class='w-100 row tbl-cont-row h-btn ${isSubSection ? 'sub-section' : ''}'>`
          pageHTMLString += `<a href='#section-${attribute}' class='${isSubSection ? 'f9' : 'f8'} c0133CC text caps ${isFirstSec ? 'first' : 'bold'}'><span>${section.title}</span></a>`;
          pageHTMLString += `<a href='#page-${i + 1}' class='f8 c0133CC ${!isFirstSec && !isSubSection ? 'bold' : ''} text page-no'><span>${i + 1}</span></a>`
          pageHTMLString += '</div>'
        }
      } else {
        if (!index) {
          index = i;
        }
      }
    }
  }
  if (index > -1) {
    pages[index][0].value = pageHTMLString;
  }
}
const fillChangeHistory = (pages, document) => {
  //remove change History
  pages.forEach((page, i) => {
    page.forEach((section, j) => {
      if (section.attribute === 'change_history') {
        pages[i].splice(j, 1);
      }
    })
    if (pages[i].length === 0) {
      pages.splice(i, 1);
    }
  });
  // add change history
  if (document && Array.isArray(document.change_history) && document.change_history.length > 0) {
    let history = [...document.change_history], i = 0;
    while (i <= history.length) {
      let chunk = history.slice(i, 30)
      pages.push([{
        ...ChangeHistory,
        showSectionTitle: i === 0,
        value: chunk
      }])
      i += 30;
    }
  }
}
export const getDocumenterPages = (iOptions) => {
  const { document, user, isSchemaOnly } = iOptions || {}
  const presentation = document.presentation || {};
  let pageConfig = document.page_config ? JSON.parse(document.page_config) : null;
  let totalPages = pageConfig ? pageConfig.length : 6;
  let pageSections = [];
  const getSectionInfo = (attribute) => {
    let sectionInfo = SectionTitle[attribute]
    let title = '';
    if (sectionInfo) {
      title = sectionInfo.title;
    }
    if (attribute === 'common_doc_type') {
      title = isSchemaOnly ? '[[DocumentType]]' : document.doc_type.label;
    } else if (attribute === 'organisation_identifier') {
      title += ` "<span class="no-caps">${user.ent_org.brandname}</span>"`;
    } else if (attribute === ChangeHistory.attribute) {
      title = ChangeHistory.title;
    }
    return { title: title, slNo: sectionInfo ? sectionInfo.slNo : '' }
  }
  const getPresentationValue = (attribute) => {
    let value = presentation[attribute] || '';
    if (value) {
      if (presentation.page_config) {
        if ((typeof value === 'string')) {
          value = JSON.parse(value)
        }
        value = Object.values(value).join()
      }
    }
    return value
  }
  for (let i = 0; i < totalPages; i++) {
    let sections = []
    let currentPageConfig = pageConfig && pageConfig[i];
    if (currentPageConfig) {
      for (let j = 0; j < currentPageConfig.length; j++) {
        const sectionConfig = currentPageConfig[j];
        if (sectionConfig) {
          const { attribute, showSectionTitle, key, onlyTitle, mainAttribute, isSubSection } = sectionConfig;
          try {
            let val = document[(isSubSection ? mainAttribute : attribute)];
            if (!onlyTitle && attribute !== 'doc_history' && attribute !== 'change_history') {
              val = (val && val[key]) ? val[key] : ''
              val = `<span style="font-family:Roboto;font-size:14px">${val || ''}</span>`
              if (val.length > 0) {
                val = performVariableMapping(val, { org: user.ent_org, document })
              }
            }
            let sectionTitleInfo = showSectionTitle ? getSectionInfo(attribute) : {};
            sections.push({ ...sectionTitleInfo, ...sectionConfig, value: val })
          } catch (error) {
            console.log(error);
          }
        }
      }
      pageSections.push(sections);
    } else {
      if (i === 1) {
        pageSections.push([{ attribute: 'tbl_of_content', title: "CONTENTS", showSectionTitle: true, allowAddVariable: false }])
      } else {
        sections = Sections.filter((section) => section.defaultPage === i);
        sections = sections.map((_section) => {
          let section = { ..._section }, docValue = document[section.attribute],
            preVal = getPresentationValue(section.attribute), newVal = '';
          switch (section.attribute) {
            case 'copyright':
            case 'doc_control': newVal = preVal; break;
            case 'doc_history':
              newVal = docValue;
              break;
            default:
              if (section.attribute === 'organisation_identifier' && user) {
                section.title += ` "<span class="no-caps">${user.ent_org.brandname}</span>"`;
              } else if (section.attribute === 'common_doc_type') {
                section.title = isSchemaOnly ? '[[DocumentType]]' : document.doc_type.label;
              }
              if (preVal) {
                newVal += preVal;
              }
              if (docValue) {
                newVal += docValue;
              }
              break;
          };
          if (!isSchemaOnly && section.attribute !== 'doc_history') {
            newVal = `<span style="font-family:Roboto;font-size:14px">${newVal || ''}</span>`
            newVal = performVariableMapping(newVal, { org: user.ent_org, document })
          }
          return { ...section, showSectionTitle: true, value: newVal }
        });
        pageSections.push(sections);
      }
    }
  }
  fillChangeHistory(pageSections, document);
  fillTableOfContents(pageSections);
  return pageSections;
}
const processDomChildren = (ele, remainingSpace, attribute) => {
  Array.prototype.forEach.call(ele.children, (child) => {
    if (child.offsetHeight > remainingSpace) {
      console.log(`section child ${attribute}`, child);
      processDomChildren(child, remainingSpace, attribute);
    } else {
      remainingSpace = remainingSpace - child.offsetHeight;
    }
  })
}
export const organizePagesSections = (pages) => {
  const CONTENT_LENGTH = 750;
  let remainingSpace = CONTENT_LENGTH;
  var domPages = document.querySelectorAll('.document-viewer .page');
  if (domPages.length !== pages.length) {
    domPages = organizePagesSections(pages);
  }
  for (let i = 0; i < pages.length; i++) {
    remainingSpace = CONTENT_LENGTH
    const sections = pages[i];
    const dom = domPages[i];
    for (let j = 0; j < sections.length; j++) {
      const section = sections[j];
      const secDom = dom.querySelector(`#section-${section.attribute}`);
      if (secDom.offsetHeight > remainingSpace) {
        processDomChildren(secDom, remainingSpace, section.attribute);
      } else {
        remainingSpace = remainingSpace - secDom.offsetHeight;
      }
    }
  }
}